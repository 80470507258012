import React, { useState } from 'react';
import PayE from './PayE';
import discountImage from '../../assets/pay/buttonbefore.png'; // 클릭 전 이미지
import discountImageActive from '../../assets/pay/buttonafter.png';

const PayC = () => {
  const [activeTab, setActiveTab] = useState('1개월');

  const renderContent = () => {
    switch (activeTab) {
      case '1개월':
        return (
            <div className="max-w-[1440px] mx-auto p-4">
              {/* 상단 VAT 별도 텍스트 */}
              {/* <div className="mb-2">
                <p className="text-gray-500 text-sm">* VAT 별도</p>
              </div> */}
              
              {/* 카드 영역 */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div className="border border-gray-300 rounded-lg h-[500px]">
                      <div className="h-[65%] bg-gray-100 p-4 flex flex-col justify-between">
                          <div className="mt-4"> {/* 상단 여백 추가 */}
                              <p className="text-black text-2xl font-bold">FREE</p> {/* 텍스트 크기 증가 */}
                              <p className="text-[#C83232] text-4xl font-bold mt-2">무료체험</p> {/* 텍스트 크기 증가 및 색상 변경 */}
                          </div>
                          <div className="py-4">
                              <button className="w-full font-semibold border border-[#C83232] bg-[#fff] text-[#C83232] px-4 py-2 rounded-md hover:bg-[#C83232] hover:text-white transition-colors duration-300 text-md">
                                  무료체험 이용하기
                              </button>
                          </div>
                      </div>
                      <div className="h-[35%] p-6 font-semibold text-sm leading-[28px]"> {/* 행간 간격 조정 */}
                          무료체험은 프리미엄 모드로<br/>
                          진행됩니다. 추후 정식가입시<br/>
                          원하시는 학습권을<br/>
                          선택하실 수 있습니다.
                      </div>
                  </div>
                  
                  <div className="border border-gray-300 rounded-lg h-[500px]">
                      <div className="h-[65%] bg-gray-100 p-4 flex flex-col justify-between">
                          <div className="mt-4">
                              <p className="text-2xl font-bold">단계별 학습권</p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-[#915D95] text-4xl font-bold mt-2">79,000<span className="text-sm text-black">원</span></p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-sm font-medium mt-6">애니메이션 강의영상과 문제풀이로<br/>입문부터 차근차근 배우는 기초탄탄<br/>단계별학습 클래스</p> {/* 추가 텍스트 */}
                          </div>
                          <div className="py-4">
                              <button className="w-full font-semibold border border-[#915D95] bg-[#fff] text-[#915D95] px-4 py-2 rounded-md hover:bg-[#915D95] hover:text-white transition-colors duration-300 text-md">
                                  단계별 학습권 이용하기
                              </button>
                          </div>
                      </div>
                      <div className="h-[35%] px-6 py-4 font-semibold text-sm leading-[36px]"> {/* 행간 간격 조정 */}
                          <span className="font-extrabold">두고팡콘텐츠 이용가능</span><br/>
                          <span className="text-[#915D95]">✓</span> 두드림 바둑<br/>
                          <span className="text-[#915D95]">✓</span> 유아바둑<br/>
                          <span className="text-[#915D95]">✓</span> 바둑놀이터
                      </div>
                  </div>

                  <div className="border border-gray-300 rounded-lg h-[500px]">
                      <div className="h-[65%] bg-gray-100 p-4 flex flex-col justify-between">
                          <div className="mt-4">
                              <p className="text-2xl font-bold">플러스 학습권</p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-[#76AA60] text-4xl font-bold mt-2">79,000<span className="text-sm text-black">원</span></p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-sm font-medium mt-6">수만개의 바둑문제를 풀어보며<br/>모든 유형을 완전 정복하는 실력향상<br/>플러스학습 클래스</p> {/* 추가 텍스트 */}
                          </div>
                          <div className="py-4">
                              <button className="w-full font-semibold border border-[#76AA60] bg-[#fff] text-[#76AA60] px-4 py-2 rounded-md hover:bg-[#76AA60] hover:text-white transition-colors duration-300 text-md">
                                  플러스 학습권 이용하기
                              </button>
                          </div>
                      </div>
                      <div className="h-[35%] px-6 py-4 font-semibold text-sm leading-[36px]"> {/* 행간 간격 조정 */}
                          <span className="font-extrabold">플러스콘텐츠 이용가능</span><br/>
                          <span className="text-[#76AA60]">✓</span> 교재별학습실<br/>
                          <span className="text-[#76AA60]">✓</span> 문제은행<br/>
                          <span className="text-[#76AA60]">✓</span> 만든문제
                      </div>
                  </div>

                  <div className="border border-gray-300 rounded-lg h-[500px]">
                      <div className="h-[65%] bg-gray-100 p-4 flex flex-col justify-between">
                          <div className="mt-4">
                              <p className="text-2xl font-bold">전체 학습권</p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-[#DFA532] text-4xl font-bold mt-2">129,000<span className="text-sm text-black">원</span></p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-sm font-medium mt-6">단계별 학습과 플러스 학습 콘텐츠를<br/>무제한으로 자유롭게 이용하는<br/>바둑 올인원학습 클래스</p> {/* 추가 텍스트 */}
                          </div>
                          <div className="py-4">
                              <button className="w-full font-semibold border border-[#DFA532] bg-[#fff] text-[#DFA532] px-4 py-2 rounded-md hover:bg-[#DFA532] hover:text-white transition-colors duration-300 text-md">
                                  전체 학습권 이용하기
                              </button>
                          </div>
                      </div>
                      <div className="h-[35%] px-6 py-4 font-semibold text-sm leading-[36px]"> {/* 행간 간격 조정 */}
                          <span className="font-extrabold">학습모드의 모든 콘텐츠 이용가능</span><br/>
                          <span className="text-[#DFA532]">✓</span> 두고팡콘텐츠의 모든기능<br/>
                          <span className="text-[#DFA532]">✓</span> 플러스콘텐츠의 모든기능<br/>
                      </div>
                  </div>
              </div>
          </div>
        );
      case '12개월':
        return (
          <div className="max-w-[1440px] mx-auto p-4">
              
              {/* 카드 영역 */}
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-7">
                  <div className="border border-gray-300 rounded-lg h-[500px]">
                      <div className="h-[65%] bg-gray-100 p-4 flex flex-col justify-between">
                          <div className="mt-4"> {/* 상단 여백 추가 */}
                              <p className="text-black text-2xl font-bold">FREE</p> {/* 텍스트 크기 증가 */}
                              <p className="text-[#C83232] text-4xl font-bold mt-2">무료체험</p> {/* 텍스트 크기 증가 및 색상 변경 */}
                          </div>
                          <div className="py-4">
                              <button className="w-full font-semibold border border-[#C83232] bg-[#fff] text-[#C83232] px-4 py-2 rounded-md hover:bg-[#C83232] hover:text-white transition-colors duration-300 text-md">
                                  무료체험 이용하기
                              </button>
                          </div>
                      </div>
                      <div className="h-[35%] p-6 font-semibold text-sm leading-[28px]"> {/* 행간 간격 조정 */}
                          무료체험은 프리미엄 모드로<br/>
                          진행됩니다. 추후 정식가입시<br/>
                          원하시는 학습권을<br/>
                          선택하실 수 있습니다.
                      </div>
                  </div>
                  
                  <div className="border border-gray-300 rounded-lg h-[500px]">
                      <div className="h-[65%] bg-gray-100 p-4 flex flex-col justify-between">
                          <div className="mt-4">
                              <p className="text-2xl font-bold">단계별 학습권</p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-[#915D95] text-4xl font-bold mt-2">23,700<span className="text-sm text-black">원 / 월</span></p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-sm font-medium mt-6">애니메이션 강의영상과 문제풀이로<br/>입문부터 차근차근 배우는 기초탄탄<br/>단계별학습 클래스</p> {/* 추가 텍스트 */}
                          </div>
                          <div className="py-4">
                              <button className="w-full font-semibold border border-[#915D95] bg-[#fff] text-[#915D95] px-4 py-2 rounded-md hover:bg-[#915D95] hover:text-white transition-colors duration-300 text-md">
                                  단계별 학습권 이용하기
                              </button>
                          </div>
                      </div>
                      <div className="h-[35%] px-6 py-4 font-semibold text-sm leading-[36px]"> {/* 행간 간격 조정 */}
                          <span className="font-extrabold">두고팡콘텐츠 이용가능</span><br/>
                          <span className="text-[#915D95]">✓</span> 두드림 바둑<br/>
                          <span className="text-[#915D95]">✓</span> 유아바둑<br/>
                          <span className="text-[#915D95]">✓</span> 바둑놀이터
                      </div>
                  </div>

                  <div className="border border-gray-300 rounded-lg h-[500px]">
                      <div className="h-[65%] bg-gray-100 p-4 flex flex-col justify-between">
                          <div className="mt-4">
                              <p className="text-2xl font-bold">플러스 학습권</p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-[#76AA60] text-4xl font-bold mt-2">23,700<span className="text-sm text-black">원 / 월</span></p> {/* 텍스트 크기 증가 및 색상 변경 */}
                              <p className="text-sm font-medium mt-6">수만개의 바둑문제를 풀어보며<br/>모든 유형을 완전 정복하는 실력향상<br/>플러스학습 클래스</p> {/* 추가 텍스트 */}
                          </div>
                          <div className="py-4">
                              <button className="w-full font-semibold border border-[#76AA60] bg-[#fff] text-[#76AA60] px-4 py-2 rounded-md hover:bg-[#76AA60] hover:text-white transition-colors duration-300 text-md">
                                  플러스 학습권 이용하기
                              </button>
                          </div>
                      </div>
                      <div className="h-[35%] px-6 py-4 font-semibold text-sm leading-[36px]"> {/* 행간 간격 조정 */}
                          <span className="font-extrabold">플러스콘텐츠 이용가능</span><br/>
                          <span className="text-[#76AA60]">✓</span> 교재별학습실<br/>
                          <span className="text-[#76AA60]">✓</span> 문제은행<br/>
                          <span className="text-[#76AA60]">✓</span> 만든문제
                      </div>
                  </div>

                  <div className="border border-[#DFA532] rounded-lg h-[540px] flex flex-col justify-between md:mt-[-40px]"> {/* 상단 마진 조정 */}
                    {/* 추천 배너 */}
                    <div className="bg-[#DFA532] text-white text-center py-2 rounded-t-lg">
                      추천
                    </div>

                    {/* 카드 내용 */}
                    <div className="h-[65%] bg-[#FAF8E3] p-4 flex flex-col justify-between">
                      <div className="mt-4">
                        <p className="text-2xl font-bold">전체 학습권</p>
                        <p className="text-[#DFA532] text-4xl font-bold mt-2">38,700<span className="text-lg text-black">원 / 월</span></p>
                        <p className="text-sm font-medium mt-6 leading-5">
                          단계별 학습과 플러스 학습 콘텐츠를<br/>
                          무제한으로 자유롭게 이용하는<br/>
                          바둑 올인원학습 클래스
                        </p>
                      </div>
                      <div className="py-4">
                        <button className="w-full font-semibold bg-[#DFA532] text-white px-4 py-2 rounded-md hover:bg-[#c3932c] transition-colors duration-300 text-md">
                          전체 학습권 이용하기
                        </button>
                      </div>
                    </div>

                    {/* 하단 내용 */}
                    <div className="h-[35%] px-6 py-4 font-semibold text-sm leading-[36px] border-t border-[#DFA532]">
                      <span className="font-extrabold">학습모드의 모든 콘텐츠 이용가능</span><br/>
                      <span className="text-[#DFA532]">✓</span> 두고팡콘텐츠의 모든기능<br/>
                      <span className="text-[#DFA532]">✓</span> 플러스콘텐츠의 모든기능
                    </div>
                  </div>
              </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-[1160px] mx-auto pt-20">
      <div className="flex justify-center space-x-4">
        <button
            onClick={() => setActiveTab('1개월')}
            className={`px-12 py-2 rounded-md text-lg font-bold ${
                activeTab === '1개월' ? 'bg-[#d49b3b] text-white' : 'bg-white border '
            }`}
            >
            1개월
        </button>
        <div className="flex justify-center space-x-4 relative">
      <div className="relative">
        <img
          src={activeTab === '12개월' ? discountImageActive : discountImage}
          alt="할인 배지"
          className="absolute -top-8 left-1/2 transform -translate-x-1/2"
        />
        <button
          onClick={() => setActiveTab('12개월')}
          className={`px-12 py-2 rounded-md text-lg font-bold ${
            activeTab === '12개월' ? 'bg-[#d49b3b] text-white' : 'bg-white border '
          }`}
        >
          12개월
        </button>
      </div>
    </div>
      </div>
      <div className="mt-8">
        {renderContent()}
      </div>
      <div className="mt-16 -mb-8">
        <PayE />
      </div>
    </div>
  );
};

export default PayC;