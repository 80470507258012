import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const InquiryForm = () => {
  const [formData, setFormData] = useState({
    subjectType: '일반 문의', // 기본 주제는 '일반 문의'
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // 해당 필드의 오류 메시지 초기화
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
    
    // 성공 메시지 초기화
    if (success) setSuccess(false);
  };

  const handleSubjectTypeChange = (type) => {
    setFormData({ ...formData, subjectType: type });
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // 이름 검증
    if (!formData.name.trim()) {
      newErrors.name = '이름을 입력해주세요';
      isValid = false;
    }

    // 이메일 검증
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = '이메일을 입력해주세요';
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = '유효한 이메일 형식이 아닙니다';
      isValid = false;
    }

    // 전화번호 검증
    const phoneRegex = /^[0-9]{2,3}-?[0-9]{3,4}-?[0-9]{4}$/;
    if (!formData.phone.trim()) {
      newErrors.phone = '전화번호를 입력해주세요';
      isValid = false;
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = '유효한 전화번호 형식이 아닙니다 (예: 010-1234-5678)';
      isValid = false;
    }

    // 제목 검증
    if (!formData.subject.trim()) {
      newErrors.subject = '제목을 입력해주세요';
      isValid = false;
    }

    // 메시지 검증
    if (!formData.message.trim()) {
      newErrors.message = '문의 내용을 입력해주세요';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    // 폼 유효성 검사
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,   // EmailJS에서 제공받은 서비스 ID
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,  // EmailJS에서 제공받은 템플릿 ID
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID       // EmailJS에서 제공받은 사용자 ID
    ).then((result) => {
        setIsLoading(false);
        setSuccess(true);
        // 폼 초기화
        setFormData({
          subjectType: '일반 문의',
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });
      }, (error) => {
        setIsLoading(false);
        setErrors({
          form: '전송 실패: ' + (error.text || '알 수 없는 오류가 발생했습니다.')
        });
        console.error('전송 실패:', error.text);
      });
  };

  return (
    <form className="max-w-[380px] md:max-w-[500px] mx-auto mt-12" onSubmit={handleSubmit}>
      {errors.form && (
        <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          {errors.form}
        </div>
      )}
      {success && (
        <div className="mb-4 p-3 bg-green-100 border border-green-400 text-green-700 rounded">
          문의가 성공적으로 전송되었습니다!
        </div>
      )}
      <div className="mb-4">
        <label className="block text-black font-bold text-lg mb-2">주제</label>
        <div className="flex space-x-2 mt-1">
          <button
            type="button"
            className={`px-4 py-2 rounded-md border ${
              formData.subjectType === '일반 문의' ? 'bg-yellow-500 text-white' : 'bg-white text-gray-700'
            }`}
            onClick={() => handleSubjectTypeChange('일반 문의')}
          >
            일반 문의
          </button>
          <button
            type="button"
            className={`px-4 py-2 rounded-md border ${
              formData.subjectType === '기능 문의' ? 'bg-yellow-500 text-white' : 'bg-white text-gray-700'
            }`}
            onClick={() => handleSubjectTypeChange('기능 문의')}
          >
            기능 문의
          </button>
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="name" className="block text-black font-bold text-lg mb-2">이름 <span className="text-red-500">*</span></label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="이름"
          className={`mt-1 p-2 w-full border rounded ${errors.name ? 'border-red-500' : ''}`}
          value={formData.name}
          onChange={handleChange}
          required
        />
        {errors.name && (
          <p className="text-red-500 text-xs mt-1">{errors.name}</p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-black font-bold text-lg mb-2">이메일 <span className="text-red-500">*</span></label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="이메일"
          className={`mt-1 p-2 w-full border rounded ${errors.email ? 'border-red-500' : ''}`}
          value={formData.email}
          onChange={handleChange}
          required
        />
        {errors.email && (
          <p className="text-red-500 text-xs mt-1">{errors.email}</p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="phone" className="block text-black font-bold text-lg mb-2">휴대폰 <span className="text-red-500">*</span></label>
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="연락처 (예: 010-1234-5678)"
          className={`mt-1 p-2 w-full border rounded ${errors.phone ? 'border-red-500' : ''}`}
          value={formData.phone}
          onChange={handleChange}
          required
        />
        {errors.phone && (
          <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="subject" className="block text-black font-bold text-lg mb-2">제목 <span className="text-red-500">*</span></label>
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="제목"
          className={`mt-1 p-2 w-full border rounded ${errors.subject ? 'border-red-500' : ''}`}
          value={formData.subject}
          onChange={handleChange}
          required
        />
        {errors.subject && (
          <p className="text-red-500 text-xs mt-1">{errors.subject}</p>
        )}
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block text-black font-bold text-lg mb-2">문의 내용 <span className="text-red-500">*</span></label>
        <textarea
          id="message"
          name="message"
          placeholder="문의 내용을 입력해 주세요."
          className={`mt-1 p-2 w-full border rounded ${errors.message ? 'border-red-500' : ''}`}
          rows="4"
          value={formData.message}
          onChange={handleChange}
          required
        />
        {errors.message && (
          <p className="text-red-500 text-xs mt-1">{errors.message}</p>
        )}
      </div>
      <button
        type="submit"
        disabled={isLoading}
        className={`${
          isLoading ? 'bg-gray-400' : 'bg-yellow-500'
        } text-white px-6 py-4 rounded w-full sm:w-2/3 mx-auto block text-center mb-12 transition-colors`}
      >
        {isLoading ? '전송 중...' : '문의하기'}
      </button>
    </form>
  );
};

export default InquiryForm;
