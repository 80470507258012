import React, { useState } from 'react';
import notice01 from '../../assets/notices/notice01.png';
import notice02 from '../../assets/notices/notice02.jpg';
import notice03 from '../../assets/notices/notice03.png';

const notices = [
  {
    id: 1,
    title: '두고팡플러스 콘텐츠-바둑문제은행 OPEN 🎉',
    date: '2025-04-03', // 날짜는 예시로 임의로 넣었습니다. 필요에 따라 수정해주세요.
    content: `두고팡플러스+ 콘텐츠\n
  "바둑을 더 배우고 싶으시다고요?
  두고팡플러스+가 기다리고 있어요!"\n
  '교재별 학습실, 문제은행' 등
  수만개의 문제를 통해 모든 바둑유형 완전 정복할수 있습니다.\n
  [학습모드]\n
  ▶교재별 학습실
  개념이해부터 심화 학습까지
  실력을 완성하는 특별한 비법\n
  ▶문제은행
  단계별 챌린지에 도전하고
  나의 기력을 측정해 볼 수 있습니다.\n
  학생과 학부모, 선생님 모두에게 최고의 선택
  이제 두고팡과 함께해보아요.`,
    image: notice03
  },
  {
    id: 2,
    title: '두고팡 콘텐츠-애니메이션 바둑 OPEN 🎉',
    date: '2025-04-03',
    content: `바둑이 즐거워진다, 두고팡\n
  교육현장의 선생님들이 직접 참여해
  완성한 바둑교육과정을 담았습니다.
  두고팡과 함께 즐거운 바둑여행을 떠나보아요.\n
  [학습모드]\n
  ▶ 영상강의
  개념을 더 즐겁게 애니메이션 영상강의
   - 재미있는 바둑 동화와 함께 즐거운 놀이처럼 바둑을 배울 수 있어요.\n
  ▶ 문제풀이
  수준별 맞춤 학습이 가능한 문제풀이
   - 영상에서 배운 개념을 직접 문제로 풀어보며 바둑 실력을 쑥쑥 기를 수 있어요.\n
  ▶ 실전학습
  게임 속 미션을 해결하며 배우는 실전바둑
   - 외계인, 공룡, 도깨비들이 나오는 다양한 미션을 해결하며 실전을 익힐 수 있어요.\n
  ▶ 학습레포트
  레포트를 통해 학습현황과 성취도 확인
   - 실시간 자동채점으로 학습결과가 반영된 레포트를 통해 취약유형을 극복할 수 있어요.\n
  ▶ 기기호환
  PC부터 모바일까지 자유로운 기기호환
   - 집에서도 교실에서도 그리고 차로 이동하면서까지도 언제, 어디서나 편리하게 이용할 수 있어요.\n
  [두고팡 학습 포인트]\n
  "바둑이 처음인가요?
  두고팡과 함께 배워보아요!"\n
  '입문-초급-중급-고급' 단계별로 바둑을 배워나갈수 있습니다.\n
  "학습능력을 향상시켜 주는 즐거운 바둑학습!"\n
  집중력, 논리력, 기억력 등 두뇌발달에 필요한 능력을 자극하는 바둑학습
  지금 바로 두고팡과 함께 시작해보아요.`,
    image: notice02
  },
  { 
    id: 3, 
    title: '두고팡 2025년도 홈페이지 OPEN 🎉', 
    date: '2025-04-03', 
    content: `창의력과 사고력을 키워주는
즐거운 바둑 플랫폼 <두고팡>
홈페이지가 오픈되었습니다\n
두고팡으로 재미있게 바둑배우기
함께 해보아요^^\n
두고팡 홈페이지의 기본적인
이용방법을 안내드릴게요\n
1. 학생앱 들어가기
 - 홈페이지 우측 하단의
 - 초록색 '학습 모드' 클릭!
 ※ 모바일은 앱스토어에서 "두고팡" 다운!\n
2. 관리자 들어가기
 - 홈페이지 우측 하단의
 - 파란색 '선생님 모드' 클릭!
 ※ 모바일은 앱스토어에서 "두고팡 관리자" 다운!\n
이용에 참고 부탁드리며,
문의사항은 아래의 카톡 상담을 문의해주세요.\n
▶이용 문의
 - 홈페이지 우측 하단의
 - 노란색 '카톡 상담' 클릭!
*상담 내용을 접수 후 순차적으로 안내드려요.\n
※세부 기능별 자세한 설명은 이용가이드를 통해 확인해보실 수 있습니다.`,
    image: notice01
  }
];

const Notice = () => {
  const [activeNotices, setActiveNotices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleNoticeClick = (id) => {
    setActiveNotices((prevActiveNotices) =>
      prevActiveNotices.includes(id)
        ? prevActiveNotices.filter((noticeId) => noticeId !== id)
        : [...prevActiveNotices, id]
    );
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNotices = notices.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(notices.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    if (pageNumber === 'prev') {
      setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
    } else if (pageNumber === 'next') {
      setCurrentPage((prevPage) => Math.min(totalPages, prevPage + 1));
    } else {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="max-w-[380px] md:max-w-[800px] mx-auto md:mx-auto mt-12 space-y-6">
      {currentNotices.map((notice, index) => (
        <div key={notice.id} className="border-b pb-4">
          <h3
            className={`text-sm md:text-xl font-bold cursor-pointer flex justify-between ${
              activeNotices.includes(notice.id) ? 'text-yellow-600' : 'text-[#333]'
            }`}
            onClick={() => handleNoticeClick(notice.id)}
          >
            <span className="mr-4 md:mr-20 tracking-[-0.1em] md:tracking-normal">{`No.${notices.length - indexOfFirstItem - currentNotices.indexOf(notice)}`}</span>
            <span className="flex-1">{notice.title}</span>
            <span className="tracking-[-0.1em] md:tracking-normal text-gray-600">{notice.date}</span>
          </h3>
          <div
            className={`overflow-hidden transition-all duration-500 ease-in-out ${
              activeNotices.includes(notice.id) ? 'max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
            }`}
          >
            {notice.image && (
              <img 
                src={notice.image} 
                alt={`${notice.title} 이미지`}
                className="w-full h-auto rounded-lg shadow-md mb-4"
              />
            )}
            <p className="text-xs md:text-base text-gray-600 whitespace-pre-line">
              {notice.content}
            </p>
          </div>
        </div>
      ))}
      
      {/* Pagination */}
      <div className="flex justify-center mt-4 space-x-2">
        {/* Previous page button */}
        <button
            className="px-3 py-1 rounded bg-gray-200 text-gray-600"
            onClick={() => handlePageClick('prev')}
            disabled={currentPage === 1}
        >
            {'<'}
        </button>

        {/* Page number buttons */}
        {[...Array(totalPages)].map((_, index) => (
            <button
            key={index + 1}
            className={`px-3 py-1 rounded ${
                currentPage === index + 1 ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-600'
            }`}
            onClick={() => handlePageClick(index + 1)}
            >
            {index + 1}
            </button>
        ))}

        {/* Next page button */}
        <button
            className="px-3 py-1 rounded bg-gray-200 text-gray-600"
            onClick={() => handlePageClick('next')}
            disabled={currentPage === totalPages}
        >
            {'>'}
        </button>
      </div>
    </div>
  );
};

export default Notice;
