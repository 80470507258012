import { analytics } from '../Firebase';
import { logEvent } from 'firebase/analytics';

export const trackEvent = (eventName, eventParams = {}) => {
  if (analytics) {
    logEvent(analytics, eventName, eventParams);
  }
};

// 자주 사용하는 이벤트 추적 함수들
export const trackPageView = (pageTitle) => {
  trackEvent('page_view', {
    page_title: pageTitle,
    page_location: window.location.href
  });
};

export const trackButtonClick = (buttonName, buttonLocation) => {
  trackEvent('button_click', {
    button_name: buttonName,
    button_location: buttonLocation
  });
};

export const trackDownload = (fileName) => {
  trackEvent('file_download', {
    file_name: fileName
  });
}; 