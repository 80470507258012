import React, { useEffect, useRef, useState } from 'react';
import areak01 from '../../assets/areak01.png'; // 수정필요

const AreaK = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();  // 요소가 보이면 관찰 중지
        }
      },
      {
        threshold: 0.3,
        rootMargin: '0px 0px -20px 0px',
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      ref={ref}
      className={`relative flex items-center justify-center text-center transition-opacity duration-1000 ease-out h-[500px] transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
      style={{
        backgroundColor: '#f8b400', // 배경색 설정 (이미지가 가려지지 않는 부분에 적용)
        backgroundImage: `url(${areak01})`, // 배경 이미지 설정
        backgroundSize: 'cover', // 이미지를 섹션 크기에 맞게 조절
        backgroundPosition: 'center', // 이미지를 가운데에 배치
        backgroundRepeat: 'no-repeat', // 이미지가 반복되지 않도록 설정
      }}
    >
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-xl md:text-4xl font-bold mb-8" style={{ lineHeight: 'calc(1em + 8px)' }}>
          학생과 학부모, 선생님 모두에게 최고의 선택<br />
          이제 두고팡과 함께하세요!
        </h2>
        <div className="flex space-x-4">
          <a href="https://freetrial.dogopang.com/">
            <button className="border border-black bg-black text-white px-6 py-3 rounded-md hover:bg-gray-800">
              무료체험하기
            </button>
          </a>
          <a href="/service?tab=4">
            <button className="border border-black text-black px-6 py-3 rounded-md bg-white hover:bg-gray-100">
              문의하기
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default AreaK;