import React from 'react';

const ControlBtn = () => {
  return (
    <div className="fixed bottom-10 md:bottom-12 right-4 space-y-2">
      <a href="https://freetrial.dogopang.com" className="flex items-center justify-center w-32 h-10 bg-red-500 text-white rounded-full shadow-lg no-underline md:w-40 md:h-12 font-bold text-[1.3em]">
        {/* <img src="/path/to/icon" alt="무료체험 아이콘" className="w-6 h-6 mr-2" /> */}
        무료 체험
      </a>
      <a href="http://pf.kakao.com/_xbiGaG/chat" className="flex items-center justify-center w-32 h-10 bg-yellow-500 text-white rounded-full shadow-lg no-underline md:w-40 md:h-12 font-bold text-[1.3em]">
        <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2Fddee47%2FbtsH2WB0CvT%2F7Ur4WSuHbB4GT1qdHMPeb1%2Fimg.png" alt="카톡 상담 아이콘" className="w-5 h-5 mr-2 md:w-6 md:h-6" />
        카톡 상담
      </a>
      <a href="https://student.dogopang.com" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-32 h-10 bg-green-500 text-white rounded-full shadow-lg no-underline md:w-40 md:h-12 font-bold text-[1.3em]">
        {/* <img src="/path/to/icon" alt="학생앱 실행 아이콘" className="w-6 h-6 mr-2" /> */}
        학습 모드
      </a>
      <a href="https://admin.dogopang.com" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-32 h-10 bg-blue-500 text-white rounded-full shadow-lg no-underline md:w-40 md:h-12 font-bold text-[1.3em]">
        {/* <img src="/path/to/icon" alt="관리자모드 아이콘" className="w-6 h-6 mr-2" /> */}
        선생님 모드
      </a>
    </div>
  );
};

export default ControlBtn;