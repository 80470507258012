import React from 'react';
import backgroundImage from '../../assets/pay/bg.png'; // 배경 이미지 경로

const PayE = () => {
  const handleDownload = () => {
    const pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/dogopang-bb3bf.appspot.com/o/%E1%84%83%E1%85%AE%E1%84%80%E1%85%A9%E1%84%91%E1%85%A1%E1%86%BC%20%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf?alt=media&token=f1c8bd02-e07d-4613-a34f-7530522b1370';
    window.open(pdfUrl, '_blank');
  };

  return (
    <section
      className="relative flex flex-col items-center justify-center text-center h-[500px] bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="text-white px-4 max-w-[800px] mx-auto">
        <h2 className="text-xl md:text-4xl font-bold leading-relaxed md:leading-[60px]">
          학생들을 위한 할인혜택을 받아보세요!
          <br />
          바둑교실(학교/학원/센터/온라인)을 대상으로
          <br />
          특별한 혜택이 제공됩니다.
        </h2>
      </div>
      <div className="flex mt-6 space-x-4">
        <a href="/questionform" className="bg-[#DFA532] text-white px-8 py-3 rounded-md hover:bg-[#f3cf68]">
          단체 도입 문의
        </a>
        <button 
          onClick={handleDownload}
          className="bg-white text-black px-8 py-3 rounded-md hover:bg-gray-100 cursor-pointer"
        >
          제안서 다운로드
        </button>
      </div>
    </section>
  );
};

export default PayE;