/*
    작업일 : 240606
    수정일 : 240822
    
    [작업내용]
    이미지 업로드 : 구글 파이어베이스 - 스토리지
    호스팅 : 구글 파이어베이스 호스팅
*/

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdBanner from './components/adBanner/AdBanner'; 
import Nav from './components/header/Nav';
import ControlBtn from './components/controlBtn/ControlBtn'; 
import Home from './pages/Home'; 
import Footer from './components/footer/Footer'; 
import Teacher from './pages/Teacher';
import Service from './pages/Service';
import Pay from './pages/Pay';
import QuestionFormPage from './pages/QuestionFormPage';
import { trackPageView } from './utils/analytics';

const App = () => {
  useEffect(() => {
    // 페이지 로드 시 이벤트 추적
    trackPageView(document.title);
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen relative">
        {/* 상단 광고 배너 */}
        <AdBanner />
        
        {/* 네비게이션 바 */}
        <Nav />

        {/* 페이지 라우팅 */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/teacher" element={<Teacher />} />
          <Route path="/service" element={<Service />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/questionform" element={<QuestionFormPage />} />
        </Routes>

        {/* 하단 푸터 */}
        <Footer />

        {/* 고정된 컨트롤 버튼 */}
        <ControlBtn className="fixed bottom-4 right-4 z-50" />
      </div>
    </Router>
  );
};

export default App;