import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const QuestionFormPage = () => {
  const [formData, setFormData] = useState({
    organizationName: '',
    organizationType: '학교', // 기본 값 설정
    otherOrganizationType: '',
    name: '',
    email: '',
    phone: '',
    expectedUsers: '',
    additionalMessage: '',
    agreeToPrivacy: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // 해당 필드의 오류 메시지 초기화
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
    
    // 성공 메시지 초기화
    if (success) setSuccess(false);
  };

  const handleOrganizationTypeChange = (type) => {
    setFormData({ ...formData, organizationType: type, otherOrganizationType: '' });
    
    // 오류 메시지 초기화
    if (errors.organizationType || errors.otherOrganizationType) {
      const newErrors = { ...errors };
      delete newErrors.organizationType;
      delete newErrors.otherOrganizationType;
      setErrors(newErrors);
    }
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
    
    // 체크박스 오류 메시지 초기화
    if (errors.agreeToPrivacy && e.target.name === 'agreeToPrivacy' && e.target.checked) {
      const newErrors = { ...errors };
      delete newErrors.agreeToPrivacy;
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // 단체 이름 검증
    if (!formData.organizationName.trim()) {
      newErrors.organizationName = '단체 이름을 입력해주세요';
      isValid = false;
    }

    // 단체 종류 검증
    if (formData.organizationType === '기타' && !formData.otherOrganizationType.trim()) {
      newErrors.otherOrganizationType = '단체 종류를 입력해주세요';
      isValid = false;
    }

    // 이름 검증
    if (!formData.name.trim()) {
      newErrors.name = '이름을 입력해주세요';
      isValid = false;
    }

    // 이메일 검증
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = '이메일을 입력해주세요';
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = '유효한 이메일 형식이 아닙니다';
      isValid = false;
    }

    // 전화번호 검증
    const phoneRegex = /^[0-9]{2,3}-?[0-9]{3,4}-?[0-9]{4}$/;
    if (!formData.phone.trim()) {
      newErrors.phone = '연락처를 입력해주세요';
      isValid = false;
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = '유효한 전화번호 형식이 아닙니다 (예: 010-1234-5678)';
      isValid = false;
    }

    // 예상 사용인원 검증
    if (!formData.expectedUsers.trim()) {
      newErrors.expectedUsers = '예상 사용인원을 입력해주세요';
      isValid = false;
    }

    // 개인정보 동의 검증
    if (!formData.agreeToPrivacy) {
      newErrors.agreeToPrivacy = '개인정보 수집 및 이용에 동의해주세요';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    // 폼 유효성 검사
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID_GROUP,
      {
        organization_name: formData.organizationName,
        organization_type: formData.organizationType !== '기타' ? formData.organizationType : formData.otherOrganizationType,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        expected_users: formData.expectedUsers,
        additional_message: formData.additionalMessage || '없음',
      },
      process.env.REACT_APP_EMAILJS_USER_ID
    ).then((result) => {
        setIsLoading(false);
        setSuccess(true);
        // 폼 초기화
        setFormData({
          organizationName: '',
          organizationType: '학교',
          otherOrganizationType: '',
          name: '',
          email: '',
          phone: '',
          expectedUsers: '',
          additionalMessage: '',
          agreeToPrivacy: false,
        });
      }, (error) => {
        setIsLoading(false);
        setErrors({
          form: '전송 실패: ' + (error.text || '알 수 없는 오류가 발생했습니다.')
        });
        console.error('전송 실패:', error.text);
      });
  };

  return (
    <div className="max-w-[600px] mx-auto mt-12 p-4">
      <h1 className="text-3xl font-bold text-center mb-2">
        단체 도입 문의하기
      </h1>
      <p className="text-gray-700 mb-8 text-center">
        두고팡 프리미엄 이용을 위한 문의를 받고 있습니다.<br/>작성해주신 내용은 담당자에게 전달되어, 상담을 진행해 드릴 예정입니다.
      </p>

      {errors.form && (
        <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
          {errors.form}
        </div>
      )}
      {success && (
        <div className="mb-4 p-3 bg-green-100 border border-green-400 text-green-700 rounded">
          문의가 성공적으로 전송되었습니다!
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="organizationName" className="block text-black font-bold text-lg mb-2">단체 이름 <span className="text-red-500">*</span></label>
          <input
            type="text"
            id="organizationName"
            name="organizationName"
            placeholder="단체 이름"
            className={`mt-1 p-2 w-full border rounded ${errors.organizationName ? 'border-red-500' : ''}`}
            value={formData.organizationName}
            onChange={handleChange}
            required
          />
          {errors.organizationName && (
            <p className="text-red-500 text-xs mt-1">{errors.organizationName}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-black font-bold text-lg mb-2">단체 종류 <span className="text-red-500">*</span></label>
          <select
            className={`mt-1 p-2 w-full border rounded ${errors.organizationType ? 'border-red-500' : ''}`}
            value={formData.organizationType}
            onChange={(e) => handleOrganizationTypeChange(e.target.value)}
            required
          >
            <option value="학교">학교</option>
            <option value="학원">학원</option>
            <option value="센터">센터</option>
            <option value="온라인">온라인</option>
            <option value="홈스쿨">홈스쿨</option>
            <option value="기업">기업</option>
            <option value="기타">기타</option>
          </select>
          {formData.organizationType === '기타' && (
            <>
              <input
                type="text"
                id="otherOrganizationType"
                name="otherOrganizationType"
                placeholder="단체 종류를 입력해주세요"
                className={`mt-2 p-2 w-full border rounded ${errors.otherOrganizationType ? 'border-red-500' : ''}`}
                value={formData.otherOrganizationType}
                onChange={handleChange}
                required
              />
              {errors.otherOrganizationType && (
                <p className="text-red-500 text-xs mt-1">{errors.otherOrganizationType}</p>
              )}
            </>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="name" className="block text-black font-bold text-lg mb-2">이름 <span className="text-red-500">*</span></label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="이름"
            className={`mt-1 p-2 w-full border rounded ${errors.name ? 'border-red-500' : ''}`}
            value={formData.name}
            onChange={handleChange}
            required
          />
          {errors.name && (
            <p className="text-red-500 text-xs mt-1">{errors.name}</p>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-black font-bold text-lg mb-2">이메일 <span className="text-red-500">*</span></label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="이메일"
            className={`mt-1 p-2 w-full border rounded ${errors.email ? 'border-red-500' : ''}`}
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">{errors.email}</p>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block text-black font-bold text-lg mb-2">연락처 <span className="text-red-500">*</span></label>
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder="연락처 (예: 010-1234-5678)"
            className={`mt-1 p-2 w-full border rounded ${errors.phone ? 'border-red-500' : ''}`}
            value={formData.phone}
            onChange={handleChange}
            required
          />
          {errors.phone && (
            <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="expectedUsers" className="block text-black font-bold text-lg mb-2">예상 사용인원 <span className="text-red-500">*</span></label>
          <input
            type="text"
            id="expectedUsers"
            name="expectedUsers"
            placeholder="예상 사용인원"
            className={`mt-1 p-2 w-full border rounded ${errors.expectedUsers ? 'border-red-500' : ''}`}
            value={formData.expectedUsers}
            onChange={handleChange}
            required
          />
          {errors.expectedUsers && (
            <p className="text-red-500 text-xs mt-1">{errors.expectedUsers}</p>
          )}
        </div>

        <div className="mb-4">
          <label htmlFor="additionalMessage" className="block text-black font-bold text-lg mb-2">기타 문의</label>
          <textarea
            id="additionalMessage"
            name="additionalMessage"
            placeholder="기타 문의 내용을 입력해주세요."
            className="mt-1 p-2 w-full border rounded"
            rows="4"
            value={formData.additionalMessage}
            onChange={handleChange}
          />
        </div>

        <div className="mb-4">
          <label className="block text-black font-bold text-lg mb-2">
            개인정보 수집 및 이용 동의 <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeToPrivacy"
              name="agreeToPrivacy"
              className={`mr-2 ${errors.agreeToPrivacy ? 'border-red-500' : ''}`}
              checked={formData.agreeToPrivacy}
              onChange={handleCheckboxChange}
              required
            />
            <label htmlFor="agreeToPrivacy">
              약관에 동의합니다.
            </label>
          </div>
          {errors.agreeToPrivacy && (
            <p className="text-red-500 text-xs mt-1">{errors.agreeToPrivacy}</p>
          )}
          <p className="mt-2 text-gray-600 text-sm">
            [약관 내용 표시] 개인정보 수집 및 이용에 대한 동의가 필요합니다.
          </p>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className={`${
            isLoading ? 'bg-gray-400' : 'bg-yellow-500'
          } text-white px-6 py-4 rounded w-full sm:w-2/3 mx-auto block text-center mb-12 transition-colors`}
        >
          {isLoading ? '전송 중...' : '문의하기'}
        </button>
      </form>
    </div>
  );
};

export default QuestionFormPage;