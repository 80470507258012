import React, { useState } from 'react';

const questions = [
  // 학습 관련
  { id: 1, category: '학습 관련', question: '하루 학습량 제한이 있나요?', answer: '두고팡은 하루 학습량에 제한을 두고 있지 않습니다. \n원하는 만큼 자유롭게 학습할 수 있습니다.\n\n단, 교재 목록 왼쪽 탭에 기재되어 있듯이 교재 내 차시당 학습은 최대 2회 재학습 (총 3회)으로 제한됩니다.' },
  { id: 2, category: '학습 관련', question: '학습 도중 종료되어도 이어서 학습 가능한가요?', answer: '학습 도중 종료되어도 이어서 학습이 가능합니다. \n학습 내용이 자동으로 저장되므로, 다음에 접속했을 때 이어서 학습할 수 있습니다.' },
  { id: 3, category: '학습 관련', question: '연습문제는 강의보기를 마쳐야만 학습 가능한가요?', answer: '연습문제는 강의보기를 마치지 않더라도 학습이 가능합니다. \n\n다만, 개념에 대한 이해가 부족할 시에는 문제풀이에 어려움이 있을 수 있습니다. \n따라서 강의보기를 통해 충분히 학습한 후 문제를 풀어보는 것이 효과적입니다.' },
  { id: 4, category: '학습 관련', question: '교재가 있어야 학습할 수 있나요?', answer: '두고팡은 온라인을 통해 강의와 문제풀이를 모두 학습할 수 있는 바둑학습 플랫폼으로 꼭 교재가 필요하지는 않습니다. \n\n그렇지만 학교나 학원 등의 실제 바둑교실에서 교재를 통해 학습을 하고 있다면, \n두고팡을 활용해 반복학습을 할 수 있기에 학습효과를 더욱 극대화 시킬 수 있습니다.' },

  // 컨텐츠 관련
  { id: 5, category: '컨텐츠 관련', question: '두고팡은 어떤 컨텐츠인가요?', answer: '두고팡 플랫폼 내 두고팡 컨텐츠는 바둑을 처음 시작하는 어린이 및 입문자에게 알맞은 컨텐츠입니다. \n\n두드림바둑, 유아바둑, 바둑놀이터 안에서 영상과 문제풀이 등 다양한 바둑교육 컨텐츠를 학습하며 \n바둑과 친해지며 바둑에 대한 이해를 높일 수 있는 바둑학습 필수 컨텐츠입니다.' },
  { id: 6, category: '컨텐츠 관련', question: '두고팡플러스는 어떤 컨텐츠 인가요?', answer: '두고팡플러스 컨텐츠는 바둑을 심화단계까지 더 배워가는 고급자에게 알맞은 컨텐츠입니다. \n\n교재별학습실, 문제은행 등을 통해 수만개의 문제를 학습하며 \n모든 바둑유형을 완전 정복할 수 있는 실력 완성 컨텐츠입니다.' },
  { id: 7, category: '컨텐츠 관련', question: '일부 교재가 열리지 않습니다.', answer: '학생 개인별 수준 맞춤학습을 지원하기 위해서 일부교재는 관리자(선생님)의 승인이 필요합니다. \n\n학습을 원하는 교재가 있으면 관리자(선생님)에게 요청하여 교재 설정을 받으시기 바랍니다.' },
  { id: 8, category: '컨텐츠 관련', question: '틀린 문제만 다시 풀어볼 수 있나요?', answer: '네 틀린 문제를 확인하고 틀린 문제만 다시 풀어보기 학습을 할 수 있습니다.  \n\n연습문제 종료 후 학습 목록에서 종료한 차시의 학습완료 버튼을 클릭 후,\n나타나는 팝업 안내창에서 오답학습 클릭시 틀린 문제만 다시 풀어보기 가능합니다.' },

  // 기기 이용
  { id: 9, category: '기기 이용', question: '두고팡은 어떻게 다운로드 받나요?', answer: '두고팡은 시·공간 제한 없이, PC와 모바일 모두 완벽히 연동되는 서비스입니다.\n\n▶ PC\n두고팡은 우측 메뉴 "학생앱 실행" or "관리자 모드"로 접속이 가능합니다.\n원활한 프로그램 사용을 위해 구글 크롬 브라우저가 필요하오니, 익스플로어 사용자분들은 필히 구글 크롬을 설치해주시기 바랍니다.\n\n▶ 스마트폰, 태블릿\n두고팡은 무료 어플리케이션을 지원합니다.\n앱스토어 접속 후, "두고팡" 검색 > 앱 다운로드 하시기 바랍니다.' },
  { id: 10, category: '기기 이용', question: '권장하는 기기 사양이 어떻게 되나요?', answer: '두고팡은  모바일 기기에서 활용할 수 있는 어플리케이션을 제공하고 있습니다.\n\n▶ 안드로이드 (구글 플레이스토어)\n　1. 해상도 : 800 x 1280 이상\n　2. 안드로이드 버젼 : 8.0 이상\n\n※현재  iOS (애플 앱스토어) 는 지원하지 않습니다. 더 나은 서비스를 위해 노력하겠습니다. \n\n만약, IOS 환경에서 활용하고 싶으시다면 크롬 앱을 활용해  홈화면 바로가기 설정 후 이용하실 수 있습니다.' },
  { id: 11, category: '기기 이용', question: '학습 이용 간 멈춤 현상이 있습니다.', answer: '학습 이용 중 멈춤 현상이 발생할 경우, 다음과 같은 방법을 시도해 보세요. \n　1. 데이터 (와이파이 접속) 환경이 원활한지\n　2. 이용 중인 디바이스가 권장 사양에 부합하는지\n　3. 마우스, 스피커 등 외부 입력기기가 잘 연결되어 있는지\n\n그럼에도 멈춘 현상이 있다면,\n　1) 홈페이지 고객센터 클릭\n　2) 1:1문의\n　3) 기능문의를 통해\n\n"이용 중인 학생계정 / 구체적인 오류내용"을 접수해주시기 바랍니다.' },
  { id: 12, category: '기기 이용', question: '기기 동시접속 환경이 가능한가요?', answer: '두고팡은 다양한 환경에서도 접속하실 수 있도록 "기기별 제한 사항 없이, 동시 접속하여 학습 가능"합니다.\n\n때문에, 학생들이 학원 등하원 길과 학교나 집 등 장소에 가리지 않고 수시로 접속하여\n바둑에 대한 노출이 극대화될 수 있는 환경을 만들어 주시길 권장합니다. \n\n단, 아이디를 공유하여 여러 학생이 활용하는 사례에서는 동시접속 사용이 제한됩니다.\n이 점 숙지해주시기 바랍니다.' },

  // 서비스 전반
  { id: 13, category: '서비스 전반', question: '학습 스케쥴은 어떻게 확인하나요?', answer: '빠른 메뉴 > 학습 스케쥴 에서 확인 가능합니다. \n\n학습 스케쥴은 일자별 학습 진도를 체크하기 위함이며 관리자(선생님)을 통해 설정됩니다. \n설정된 스케쥴에 따라, 학습 진행하시기 바랍니다.' },
  { id: 14, category: '서비스 전반', question: '성적 결과는 어디에서 확인할 수 있나요?', answer: '빠른 메뉴 > 학습 레포트 에서 확인 가능합니다. \n학습 레포트는 일자별로 조회하는 레포트 조회 / 기간별로 조회하는 레포트 분석이 있습니다. \n\n레포트 분석에서는 원하는 기간 만큼 성적 조회하여 \n오답률, 학습시간 등 학습 분석이 가능하며, 개별 차시의 오답문항도 조회할 수 있습니다. \n\n분석결과를 통해 학부모님과의 상담, 진도 체크 등 학습관리 전반으로 활용할 수 있습니다.' },
  { id: 15, category: '서비스 전반', question: '리더보드의 기능은 무엇인가요?', answer: '빠른 메뉴 > 리더보드에서 확인 가능합니다. \n\n같은 바둑교실 내 학생들의 성적 결과에 따른 차등 순위를 확인할 수 있습니다. \n이를 통해 학습 동기부여를 증대 시키고, 학생 간 학습을 유발하여 효과를 증대 시킵니다. \n\n매월 1일~말일까지 결과를 통해 1~3등까지 수상이 가능하며, 이는 수상 이력에 저장됩니다.' },
  { id: 16, category: '서비스 전반', question: '학습 설정은 어떻게 할 수 있나요?', answer: '빠른 메뉴 > 학습설정 에서 확인 가능합니다. \n\n학생이 설정 가능한 내역은 효과음 사용여부 입니다. \n\n나머지 학습설정 내역은 무분별한 학습 진행을 방지하고자 관리자(선생님)가 직접 관리합니다.' }
];

const Question = () => {
  const [activeQuestions, setActiveQuestions] = useState([]);

  const handleQuestionClick = (id) => {
    if (activeQuestions.includes(id)) {
      setActiveQuestions(activeQuestions.filter(q => q !== id));
    } else {
      setActiveQuestions([...activeQuestions, id]);
    }
  };

  // 그룹화된 카테고리별로 소제목을 표시합니다.
  const groupedQuestions = questions.reduce((acc, curr) => {
    if (!acc[curr.category]) {
      acc[curr.category] = [];
    }
    acc[curr.category].push(curr);
    return acc;
  }, {});

  return (
    <div className="space-y-8 max-w-[380px] md:max-w-[800px] mx-auto">
      {Object.keys(groupedQuestions).map((category) => (
        <div key={category}>
          <h2 className="text-xl font-bold text-yellow-500 mt-12 mb-6">{`[${category}]`}</h2>
          {groupedQuestions[category].map((q) => (
            <div key={q.id} className="border-b pb-6">
              <h3
                className={`text-lg mt-6 font-bold cursor-pointer ${
                  activeQuestions.includes(q.id) ? 'text-yellow-600' : 'text-[#333]'
                }`}
                onClick={() => handleQuestionClick(q.id)}
              >
                {q.question}
              </h3>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  activeQuestions.includes(q.id) ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <p className="text-gray-600 mt-4 whitespace-pre-line">
                  {q.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Question;
